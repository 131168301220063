<template>
  <div class="mng-container">
    <mng-header page-title="系统接入详情" msg="">
      <el-button size="small" @click="goBack">返 回</el-button>
    </mng-header>
    <div class="mng-body">
      <div class="form-area">
        <el-form :model="dataForm" ref="dataForm" label-suffix=":" label-width="100px">
          <el-form-item label="系统名称" prop="serviceName">
            <div class="detail-value">{{dataForm.serviceName}}</div>
          </el-form-item>
          <el-form-item label="跳转路径" prop="servicePath">
            <div class="detail-value">{{dataForm.servicePath}}</div>
          </el-form-item>
          <el-form-item label="系统描述" prop="description">
            <div class="detail-value">{{dataForm.description}}</div>
          </el-form-item>
          <el-form-item label="启用状态" prop="region">
            {{dataForm.status==='1'?'已启用':'已停用'}}
          </el-form-item>
          <el-form-item label="系统图标" prop="serviceImgPath">
            <el-image
              v-if="dataForm.serviceIconPath"
              style="max-width: 140px; max-height: 140px"
              :src="dataForm.serviceIconPath">
            </el-image>
            <span v-else>未设置</span>
          </el-form-item>
          <el-form-item label="背景图片" prop="serviceImgPath">
            <el-image
              v-if="dataForm.serviceImgPath"
              style="max-width: 140px; max-height: 140px"
              :src="dataForm.serviceImgPath">
            </el-image>
            <span v-else>未设置</span>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import MngHeader from '@/components/mng-pages/MngHeader'
import { sADetail } from '@/service/api/sys-access/system-access'
export default {
  name: 'SystemAccessMng',
  components: {
    MngHeader
  },
  props: {
    pageData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      isEdit: false,
      submitLoading: false,
      loadingNoteTypeList: false,
      noteTypeList: [],
      // 表单数据
      dataForm: {
        serviceName: '',
        servicePath: '',
        status: '',
        description: '',
        serviceIconPath: '',
        serviceImgPath: ''
      },
      serviceIconName: '',
      serviceImgName: '',
      // 编辑器配置
      toolbarConfig: {
        excludeKeys: ['fullScreen', 'uploadVideo', 'uploadImage']
      },
      editorConfig: {
        readOnly: false
      }
    }
  },
  mounted() {
    if (this.pageData && this.pageData.id) {
      this.isEdit = true
      this.getNoteDetail(this.pageData.id)
    }
  },
  methods: {
    goBack() {
      this.$emit('changePage', { pageId: 'SystemAccessTable' })
    },
    // 获取公告详情
    getNoteDetail() {
      this.detailLoading = true
      sADetail({ id: this.pageData.id }).then(res => {
        this.detailLoading = false
        if (res.data.status === 200) {
          this.dataForm = res.data.data
        } else {
          this.$message.error(res.data.msg || '获取系统接入详情失败！')
        }
      }).catch(e => {
        console.log(e)
        this.detailLoading = false
        this.$message.error('服务异常！')
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/common/mng-pages.scss";
.upload-img-area{
  .delImg{
    color: #e8413d;
    padding: 2px;
    &:hover{
      border: 1px solid;
    }
  }
}
.detail-value{
  width: 100%;
  word-break: break-all;
}
</style>
